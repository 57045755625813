export const useMobileNavigation = () => {
  const isOpen = useState("isMobileNavOpen", () => false);

  const toggle = () => {
    isOpen.value = !isOpen.value;
  };

  return {
    isOpen,
    toggle,
  };
};

export const useFormToggle = () => {
  // const isOpen = useState("isFormOpen", () => false);
  const activeFormId = useState<string | null>("activeFormId", () => null);

  const open = (uuid: string) => {
    activeFormId.value = uuid;
    // isOpen.value = true;
  };

  const close = () => {
    // isOpen.value = false;
    activeFormId.value = null;
  };

  return {
    // isOpen,
    activeFormId,
    open,
    close,
  };
};
